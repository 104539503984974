import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import styles from "./style.module.css";
import plane from "../../../../assets/images/plane.svg";
import shield from "../../../../assets/images/shield-01.svg";
import certificate from "../../../../assets/images/certificate-01.svg";
import trash from "../../../../assets/images/trash-01.svg";
import logOut from "../../../../assets/images/log-out-04.svg";
import mail from "../../../../assets/images/mail-01.svg";
import guidlines from "../../../../assets/images/users-edit.svg";
import { useNavigate } from "react-router-dom";
import { SettingsContext } from "../../../../context/settings-context";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "../../../../context/theme-context";

const CommunityGuidlines_Url =
  "https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28b6d7c89e05bfa45b376_LYNX%20Community%20Guidelines.pdf";
const Privacy_Url =
  "https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28b95390d57d916ca0b80_LYNX%20Privacy%20Policy.pdf";
const Terms_Url =
  "https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28be0d84d81bc4859e400_LYNX%20Terms%20%26%20Conditions.pdf";

const Menu = () => {
  const { theme, darkMode } = useTheme();
  const { logout } = useAuth0();
  const { openDeleteConfirmation, setOpenDeleteConfirmation } =
    useContext(SettingsContext);
  const navigate = useNavigate();
  const handleSubStatus = () => {
    // navigate("/subscription-status");
  };
  const handlePrivacy = () => {
    navigate("/privacy&policy");
  };
  const handleTermsOfService = () => {
    navigate("/terms-of-service");
  };
  const handleCommunityGuidlines = () => {
    /////////////
  };
  const handleEmailPreferences = () => {
    navigate("/email-preferences");
  };
  const handleDeleteAcount = () => {
    setOpenDeleteConfirmation(true);
  };
  // this function is used to download files
  const downloadFileAtUrl = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };
  const handleLogout = () => {
    const darkMode = localStorage.getItem("darkMode");
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("darkMode", darkMode);
    logout();
  };

  return (
    <Box className={styles.menu}>
      <Box className={styles.content}>
        <Typography
          variant="body1"
          sx={{
            color: darkMode
              ? theme.palette.darkgrey.darkgrey600
              : theme.palette.darkgrey.darkgrey300,
          }}
        >
          Settings
        </Typography>
        <Box className={styles.menus}>
          <Button
            startIcon={<img src={plane} alt="palne" />}
            className={styles.item}
            onClick={handleSubStatus}
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
            }}
          >
            Subscription Status
          </Button>
          <Button
            startIcon={<img src={shield} alt="shield" />}
            className={styles.item}
            onClick={() => downloadFileAtUrl(Privacy_Url)}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Privacy Policy
          </Button>
          <Button
            startIcon={<img src={certificate} alt="certificate" />}
            className={styles.item}
            onClick={() => downloadFileAtUrl(Terms_Url)}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Terms Of Service
          </Button>
          <Button
            startIcon={<img src={guidlines} alt="guidlines" />}
            className={styles.item}
            onClick={() => downloadFileAtUrl(CommunityGuidlines_Url)}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Community Guidelines
          </Button>
          <Button
            startIcon={<img src={mail} alt="mail" />}
            className={styles.item}
            onClick={handleEmailPreferences}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Email Preferences
          </Button>
          <Button
            startIcon={<img src={trash} alt="trash" />}
            className={styles.item}
            onClick={handleDeleteAcount}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Delete Account
          </Button>
          <Button
            startIcon={<img src={logOut} alt="log out" />}
            onClick={handleLogout}
            className={styles.item}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Sign out
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Menu;
