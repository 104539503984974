import { Box, Button, Typography } from '@mui/material';
import fileIcon from '../../../assets/images/file-plus-02.svg';
import styles from "./style.module.css";
import { useTheme } from '../../../context/theme-context';


function NoShoutOuts() {
    const { theme } = useTheme();

    return (
        <Box className={styles.Beta}>
            <Box className={styles.top}>
                <Box className={styles.succession}>
                    <img src={fileIcon} alt={"icon"} />
                </Box>
                <Typography
                    sx={{
                        alignSelf: 'stretch',
                        textAlign: 'center',
                        color: theme.palette.darkgrey.darkgrey700,
                        fontSize: '20px',
                        fontWeight: '700',
                    }}
                >
                    Complete your profile to be eligible to receive shout outs ☺
                </Typography>
            </Box>
            <Button
                variant="contained"
                sx={{ width: '283px' }}
            >
                Complete Profile
            </Button>
        </Box>
    );
}

export default NoShoutOuts