import { Box, Button, Chip, Typography } from "@mui/material";
import React, { useState } from "react";

import styles from "./style.module.css";
import Text from "./text";
import { useTheme } from "../../context/theme-context";

const ShoutOuts = ({ user }) => {
  const { theme } = useTheme();
  const [showReceived, setShowReceived] = useState(true);
  const [showGiven, setShowGiven] = useState(false);

  const handleReceived = () => {
    setShowReceived(true);
    setShowGiven(false);
  };
  const handleGiven = () => {
    setShowGiven(true);
    setShowReceived(false);
  };

  const styleActive = {
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    minWidth: "auto",
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: "#FFF",
    }
  };
  const styleNotActive = {
    minWidth: "auto",
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: "#FFF",
    }
  };

  return (
    <Box
      className={styles.newClassName}
      sx={{ backgroundColor: theme.palette.lightgrey.lightgrey600 }}
    >
      <Box className={styles.content}>
        <Box className={styles.tab}>
          <Chip
            label={"Received"}
            onClick={handleReceived}
            sx={showReceived ? styleActive : styleNotActive}
          />
          <Chip
            label={"Given"}
            onClick={handleGiven}
            sx={showGiven ? styleActive : styleNotActive}
          />
        </Box>
        <Text showReceived={showReceived} showGiven={showGiven} user={user} />
      </Box>
    </Box>
  );
};

export default ShoutOuts;
