import axios from "axios";

export const uploadImage = async (file, file_name) => {
  try {
    const response = await axios.post(
      "https://lynx-server.vercel.app/api/images/presigned-url",
      { fileName: file_name, fileType: file.type }
    );
    const presignedUrl = response.data.signedUrl;

    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    await axios.put(presignedUrl, file, options);
    const parsedUrl = new URL(presignedUrl);

    // Get the base URL without query parameters
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.pathname}`;

    return baseUrl;
  } catch (err) {
    console.log(err, "from err");
  }
};
