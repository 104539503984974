import React, { useContext, useState } from "react";
import { Box, Button } from "@mui/material";
import styles from "./style.module.css";
import Basics from "./components/basics";
import Contact from "./components/contact";
import RolesList from "./components/RolesList";

import { useNavigate } from "react-router-dom";
import supabase from "../../../../config/supabaseClient";
import { RoleContext } from "../../../../context/role-context";
import moment from "moment";
import { UserContext } from "../../../../context/user-context";
import { useTheme } from "../../../../context/theme-context";
import { addProjectSubmitionTag } from "../../../../api/active-campaign";

const ProjectForm = ({ setIsOpen }) => {
  const { theme } = useTheme();
  const [title, setTitle] = useState("");
  const [subheading, setSubheading] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const { roles, setRoles } = useContext(RoleContext);
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const basicProps = {
    title: title,
    setTitle: setTitle,
    subheading: subheading,
    setSubheading: setSubheading,
    description: description,
    setDescription: setDescription,
    country: country,
    setCountry: setCountry,
  };

  const contactProps = {
    emailAddress: emailAddress,
    setEmailAddress: setEmailAddress,
  };

  const handleNavigate = () => {
    navigate("/job-submission");
  };

  const handleSubmitProject = async () => {
    const { data, error } = await supabase
      .from("projects")
      .insert({
        title: title,
        subheading: subheading,
        description: description,
        location: country,
        status: "Under Review",
        contact_email: emailAddress,
        submitted_by: user.id,
        created_at: new Date(),
      })
      .select();

    const project = data[0];
    if (data) {
    } else if (error) {
      console.error("Error inserting into projects table:", error);
    }

    const { error: projects_status } = await supabase
      .from("projects_status")
      .insert({
        project_id: project.id,
        status: "Under Review",
        date_acquired: moment().format("YYYY-MM-DD"),
      });
    await roles.map(async (item) => {
      const record = {
        project_id: project.id,
        category: item.selectedCategory,
        creator_skill: item.selectedSkill,
        speciality: item.selectedSpecialty,
      };

      const { error: projects_roles } = await supabase
        .from("projects_roles")
        .insert(record);
    });
    setRoles([
      {
        num: 1,
        selectedCategory: "",
        selectedSkill: "",
        selectedSpecialty: "",
      },
    ]);
    await addProjectSubmitionTag(user.email);
    await setIsOpen(true);
  };

  const handleCancel = () => {
    navigate("/projects");
  };

  return (
    <Box component="form" className={styles.projectForm}>
      <Basics {...basicProps} />

      <RolesList />

      <Contact {...contactProps} />
      <Box className={styles.button}>
        <Button variant="contained" onClick={handleSubmitProject} fullWidth>
          submit
        </Button>
        <Button
          onClick={handleCancel}
          variant="text"
          fullWidth
          sx={{
            color: theme.palette.darkgrey.darkgrey400,
            textTransform: "capitalize",
            fontSize: "16px",
            fontWeight: "500",
            p: "0px",
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectForm;
