import React, { useState, useRef } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropUtils"; // You'll need to implement this utility function
import styles from "./style.module.css";
import { Box, Typography } from "@mui/material";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import LoadingButton from "../../../../../../../components/loading/button";


const ASPECT_RATIO = 1;
const MIN_DIMENSION = 200;

const ImageCropper = ({
  imageSrc,
  onCrop,
  onCancel,
  isProfileUploading,
  setIsProfileUploading,
}) => {

  const [crop, setCrop] = useState();
  const imgRef = useRef(null);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  }

  const handleCrop = async () => {
    const croppedImage = await getCroppedImg(
      imgRef.current,
      convertToPixelCrop(
        crop,
        imgRef.current.width,
        imgRef.current.height
      )
    );

    onCrop(croppedImage);
  };
  
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#040d12',
        height: '100%'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: {
            xs: '100%',
            md: "400px",
          },
          height: '100%'
        }}
      >
        {imageSrc && (
          <Box className={styles.crop}>
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop)=> setCrop(percentCrop)}
              // circularCrop
              keepSelection
              aspect={16 / 10.6}
              minWidth={MIN_DIMENSION}
            >
              <img
                ref={imgRef}
                src={imageSrc}
                alt="upload"
                crossOrigin = 'anonymous'
                style={{maxHeight: '100vh'}}
                onLoad={onImageLoad}
              />
            </ReactCrop>  
          </Box>
        )}
        <Box className={styles.frame}>
          <Box className={styles.content}>
          <Typography
            variant="body1"
            fontWeight={500}
            onClick={() => onCancel()}
            sx={{
              cursor: "pointer",
            }}
          >
            Cancel
          </Typography>
          <Typography
            variant="body1"
            fontWeight={500}
            onClick={handleCrop}
            sx={{
              cursor: "pointer",
            }}
          >
            <div onClick={() => setIsProfileUploading(true)}>
              {isProfileUploading ? <LoadingButton /> : "choose"}
            </div>
          </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCropper;
