import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./style.module.css";
import supabase from "../../../../../../../../config/supabaseClient";
import { RoleContext } from "../../../../../../../../context/role-context";
import deleteIcon from "../../../../../../../../assets/images/trash-011.svg";
import { useTheme } from "../../../../../../../../context/theme-context";

const RoleShow = ({ role, num }) => {
  const { theme } = useTheme();
  const [selectedCategory, setSelectedCategory] = useState(
    role.selectedCategory
  );
  const [selectedSkill, setSelectedSkill] = useState(role.selectedSkill);
  const [selectedSpecialty, setSelectedSpecialty] = useState(
    role.selectedSpecialty
  );
  const [categories, setCategories] = useState([]);
  const [skills, setSkills] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const { roles, setRoles } = useContext(RoleContext);

  const handleChangeCategory = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleChangeSkill = (event, newValue) => {
    setSelectedSkill(newValue);
  };

  const handleChangeSpecialty = (event, newValue) => {
    setSelectedSpecialty(newValue);
  };

  // delete role from roles state when delete icon clicked
  const handleDeleteClick = async () => {
    const filteredRoles = await roles.filter((item) => {
      return item.num !== role.num;
    });
    const updatedRoles = filteredRoles.sort((a, b) => (a.num > b.num ? 1 : -1));
    setRoles(updatedRoles);
  };

  // function that update current role in roles state
  const updateRoles = () => {
    const updatedRole = {
      num: role.num,
      selectedCategory,
      selectedSkill,
      selectedSpecialty,
    };

    const newRoles = roles.map((item) => {
      if (item.num === role.num) {
        return updatedRole;
      }
      return item;
    });

    setRoles(newRoles);
  };

  // invoke updateRoles function
  useEffect(() => {
    updateRoles();
  }, [selectedCategory, selectedSkill, selectedSpecialty]);

  // fetching categories and skills and speciality

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase.from("creator_types").select("*");

      const set = new Set(data.map((item) => item.category));
      setCategories(Array.from(set));
      //setSelectedCategory(data[0].category);
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSkills = async () => {
      const { data, error } = await supabase
        .from("skills")
        .select("*")
        .eq("category", selectedCategory);
      const set = new Set(data.map((item) => item.skill));
      setSkills(Array.from(set));
    };

    fetchSkills();
  }, [selectedCategory]);

  useEffect(() => {
    const fetchSpecialities = async () => {
      const { data, error } = await supabase
        .from("skills")
        .select("*")
        .eq("skill", selectedSkill);
      const set = new Set(data.map((item) => item.speciality));
      setSpecialities(Array.from(set));
    };

    fetchSpecialities();
  }, [selectedSkill]);

  return (
    <React.Fragment>
      <Box className={styles.content}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontWeight: "500",
          }}
        >
          Role #{num}
        </Typography>
        {roles.length > 1 && (
          <IconButton onClick={handleDeleteClick}>
            <img
              src={deleteIcon}
              alt="delete"
              style={{ fill: theme.palette.darkgrey.darkgrey300 }}
            />
          </IconButton>
        )}
      </Box>
      <Box className={styles.inputField}>
        <Autocomplete
          sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
          options={categories ? categories : []}
          getOptionLabel={(option) => option}
          value={selectedCategory}
          onChange={handleChangeCategory}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Select Category" variant="outlined" />
          )}
        />
        <Autocomplete
          sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
          options={skills ? skills : []}
          getOptionLabel={(option) => option}
          onChange={handleChangeSkill}
          value={selectedSkill}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Select Skill" variant="outlined" />
          )}
        />
        <Autocomplete
          sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
          options={specialities ? specialities : []}
          getOptionLabel={(option) => option}
          value={selectedSpecialty}
          onChange={handleChangeSpecialty}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Specialty"
              variant="outlined"
            />
          )}
        />
      </Box>
    </React.Fragment>
  );
};

export default RoleShow;
