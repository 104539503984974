import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import BasicLogo from "./logo";
import styles from "./style.module.css";
import ProjectForm from "./project-form";
import JobSubmission from "../job-submission";
import { useTheme } from "../../../context/theme-context";

const BoardSubmitionForm = () => {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      className={styles.boardSubmission}
      style={{ paddingBottom: "5rem" }}
      sx={{ bgcolor: theme.palette.lightgrey.lightgrey500 }}
    >
      <JobSubmission isOpen={isOpen} setIsOpen={setIsOpen} />
      <Box
        sx={{
          width: {
            xs: "100%",
            // md: "50%",
          },
        }}
      >
        <BasicLogo />
        <ProjectForm setIsOpen={setIsOpen} />
      </Box>
    </Box>
  );
};

export default BoardSubmitionForm;
