import { createContext, useEffect, useState } from "react";

export const SettingsContext = createContext(null);
const SettingsContextProvider = (props) => {
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    const contextValue = {
        openDeleteConfirmation,
        setOpenDeleteConfirmation,
    };

    return (
        <SettingsContext.Provider value={contextValue}>
        {props.children}
        </SettingsContext.Provider>
    );
};
export default SettingsContextProvider;