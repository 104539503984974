import { getActiveElement } from "@mui/x-date-pickers/internals";
import axios from "axios";

export const addEmailToActiveCampaign = async (email, firstName) => {
  const result = await axios.post("https://lynx-server.vercel.app/api", {
    email: email,
    firstName,
  });
  const contactId = await getActiveCampaignEmailId(email);
  await addStarterTag(contactId);
};
const addTag = async (contactId, tagId) => {
  const result = await axios.post(
    "https://lynx-server.vercel.app/api/contacts/tags" +
      "?contactId=" +
      contactId +
      "&tagId=" +
      tagId
  );
};
export const addStarterTag = async (contactId) => {
  const result = await addTag(contactId, 1);
};
export const addProfileCompleteTag = async (userEmail) => {
  const contactId = await getActiveCampaignEmailId(userEmail);
  const result = await addTag(contactId, 19);
};
export const addProjectSubmitionTag = async (userEmail) => {
  const contactId = await getActiveCampaignEmailId(userEmail);
  const result = await addTag(contactId, 20);
};
export const addMakeLynxBetterTag = async (userEmail) => {
  const contactId = await getActiveCampaignEmailId(userEmail);
  const result = await addTag(contactId, 23);
};
export const addHelpWithClassificationTag = async (userEmail) => {
  const contactId = await getActiveCampaignEmailId(userEmail);
  const result = await addTag(contactId, 24);
};
export const addDailyMatchTag = async (userEmail) => {
  const contactId = await getActiveCampaignEmailId(userEmail);
  const result = await addTag(contactId, 15);
};

export const addWeeklyMatchTag = async (userEmail) => {
  const contactId = await getActiveCampaignEmailId(userEmail);
  const result = await addTag(contactId, 16);
};
export const addMontlyMatchTag = async (userEmail) => {
  const contactId = await getActiveCampaignEmailId(userEmail);
  const result = await addTag(contactId, 17);
};
export const addUnSubscribeTag = async (userEmail) => {
  const contactId = await getActiveCampaignEmailId(userEmail);
  const result = await addTag(contactId, 18);
};
export const getActiveCampaignEmailId = async (email) => {
  const result = await axios.get(
    "https://lynx-server.vercel.app/api?email=" + email
  );

  return result?.data?.contacts[0]?.id;
};
export const deleteActiveCampaignEmail = async (contactId) => {
  const result = await axios.delete(
    "https://lynx-server.vercel.app/api?id=" + contactId
  );
};
export const deleteContactEmail = async (email) => {
  const contactId = await getActiveCampaignEmailId(email);

  await deleteActiveCampaignEmail(contactId);
};
