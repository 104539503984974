import React, { useRef, useState, useEffect, useContext } from "react";

import "cropperjs/dist/cropper.min.css";
import { Box, Button, Typography } from "@mui/material";
import styles from "./style.module.css";
import { UserContext } from "../../../../../context/user-context";
import { ProfileImageContext } from "../../../../../context/profile-image-context";
import supabase from "../../../../../config/supabaseClient";
import { useTheme } from "../../../../../context/theme-context";

const DeleteProfileImage = ({ modal }) => {
  const { theme } = useTheme();
  const { user, setUser } = useContext(UserContext);
  const { setSelectedImage, setIsDeleteOpen, croppedImage } =
    useContext(ProfileImageContext);
  const profile_image = user?.profile_image;
  const handleDelete = async () => {
    try {
      setIsDeleteOpen(false);
      setSelectedImage(null);
      const { error, data } = await supabase
        .from("users")
        .update({
          profile_image: null,
          original_image: null,
        })
        .eq("id", user.id);

      if (error) {
        console.error("Error uploading profile image:", error);
      } else {
        // Handle any additional logic after a successful upload
      }
      const { data: updatedUser, error: updatedUserError } = await supabase
        .from("users")
        .select("*")
        .eq("email", user.email);
      setUser(updatedUser[0]);
    } catch (error) {
      console.error("Error handling upload:", error);
    }
  };
  const handleCancel = () => {
    setIsDeleteOpen(false);
    setSelectedImage(null);
  };
  return (
    <Box className={styles.parent}>
      <Box
        className={styles.container}
        sx={{
          backgroundImage: `url(${user?.original_image})`,
          width: {
            xs: "100%",
            md: "400px",
          },
        }}
      >
        <Box className={styles.frame}>
          <Box className={styles.content}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleDelete}
              sx={{
                textTransform: "capitalize",
                bgcolor: "#fff",
                color: theme.palette.danger.red500,
                fontWeight: 500,
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCancel}
              sx={{
                textTransform: "capitalize",
                bgcolor: "#fff",
                color: theme.palette.secondary.dark,
                fontWeight: 500,
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteProfileImage;
