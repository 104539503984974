import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import myCard from "../../assets/images/nav-my-card.svg";
import myCardActive from "../../assets/images/nav-my-card-active.svg";
import discover from "../../assets/images/nav-discover.svg";
import discoverActive from "../../assets/images/nav-discover-active.svg";
import projects from "../../assets/images/nav-projects.svg";
import projectsActive from "../../assets/images/nav-projects-active.svg";
import settings from "../../assets/images/nav-settings.svg";
import settingsActive from "../../assets/images/nav-settings-active.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user-context";
import ReactGA from "react-ga4";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { useTheme } from "../../context/theme-context";

function BottomNavBar() {
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(0);
  const { user } = React.useContext(UserContext);
  const location = useLocation();
  const { theme, darkMode } = useTheme();

  React.useEffect(() => {
    if (user) {
      ReactGA.send({
        hitType: "pageview",
        page: pathname,
        title: pathname,
      });
      console.log("sent to google");
    }
  }, [location]);

  const isMyCardSelected = value === 0;
  const isDiscoverSelected = value === 1;
  const isProjectsSelected = value === 2;
  const isSettingsSelected = value === 3;

  if (
    !user ||
    pathname === "/" ||
    pathname === "/signup" ||
    pathname === "/onboarding" ||
    pathname === "/about-edit" ||
    pathname === "/add-skill" ||
    pathname === "/edit-skill" ||
    pathname === "/editSkill/:id" ||
    pathname === "/terms-of-service" ||
    pathname === "/discover"
  )
    return <div></div>;

  return (
    <Box
      sx={{
        zIndex: "999",
        width: {
          xs: "100%",
          md: "400px",
        },
        position: "fixed",
        bottom: "0",
        background: darkMode
          ? '#333333'
          : theme.palette.lightgrey.lightgrey00,
        padding: "12px 24px",
        borderTop: "1px solid",
        borderColor: darkMode
          ? '#525252'
          : theme.palette.lightgrey.lightgrey700,
        "& .MuiBottomNavigationAction-label": {
          gap: "8px",
          "&.Mui-selected": {
            color: darkMode && '#FFF',
          }
        },
        "& .MuiBottomNavigation-root": {
          backgroundColor: 'inherit'
        }
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="My Card"
          icon={
            isMyCardSelected ? <img src={myCardActive} /> : <img src={myCard} />
          }
          LinkComponent={Link}
          to="/profile"
        />

        <BottomNavigationAction
          label="Discover"
          icon={
            isDiscoverSelected ? (
              <img src={discoverActive} />
            ) : (
              <img src={discover} />
            )
          }
          LinkComponent={Link}
          to="/discover"
        />

        <BottomNavigationAction
          label="Projects"
          icon={
            isProjectsSelected ? (
              <img src={projectsActive} />
            ) : (
              <img src={projects} />
            )
          }
          LinkComponent={Link}
          to="projects"
        />
        <BottomNavigationAction
          label="Settings"
          icon={
            isSettingsSelected ? (
              <img src={settingsActive} />
            ) : (
              <img src={settings} />
            )
          }
          LinkComponent={Link}
          to="settings"
        />
      </BottomNavigation>
    </Box>
  );
}

export default BottomNavBar;
