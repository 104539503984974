import { Box, Button, Typography } from "@mui/material";
import React from "react";
import logo from "../../../assets/images/Color=Gredient, Size=Large.svg";

import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../context/theme-context";

const ProjectLanding = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    // Use navigate to go to the "/projct-board-submission" route
    navigate("/project-board-submission");
  };

  return (
    <Box className={styles.landing}>
      <Box
        className={styles.container}
        sx={{ backgroundColor: theme.palette.lightgrey.lightgrey500 }}
      >
        <Box className={styles.logo}>
          <img src={logo} width="234px" height="80px" alt="LYNX Logo" />
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.darkgrey.darkgrey500,
              fontSize: "18px",
            }}
          >
            Do you have a project looking for collaborators?
          </Typography>
        </Box>
        <Box className={styles.button}>
          <Button
            variant="contained"
            onClick={handleClick}
            sx={{
              width: "278px",
            }}
          >
            Submit a Project Idea
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectLanding;
