import React, { useContext, useState } from "react";
import styles from "./step3.module.css";

import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from "@mui/material";
import { RegistrationContext } from "../../../context/registration-context";
import useCountries from "../../../helpers/getAllCountries";
import moment from "moment";
import UnderageDialog from "./components/underage";
import { ChevronRight } from "@mui/icons-material";
import { useTheme } from "../../../context/theme-context";

const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "Germany",
  "France",
  "Australia",
  // Add more countries as needed
];

const pronouns = [
  "He/Him",
  "She/Her",
  "They/Them",
  // Add more pronoun options as needed
];

const Step3 = ({ onButtonClick }) => {
  const { theme, darkMode } = useTheme();
  const { countriesNames } = useCountries();
  const [isUnderageDialogeOpen, setIsUnderageDialogeOpen] = useState(false);

  const handlePronounsChange = (event) => {
    setSelectedPronouns(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const birthdate = Math.abs(moment(birthYear).diff(moment(), "year"));

    // check if birthdate is valid
    if (!moment(birthYear, "MM-DD-YYYY", true).isValid()) {
      return alert(
        "Invalid birthdate format. Please enter the date in MM-DD-YYYY format."
      );
    }

    // check if user age is gratter than 12
    if (birthdate < 13) {
      return setIsUnderageDialogeOpen(true);
    }

    // Call the callback function to move to the next step
    onButtonClick();
  };

  const handleCountryChange = (event, value) => {
    setSelectedCountry(value);
  };
  const {
    postalCode,
    setPostalCode,
    country: selectedCountry,
    setCountry: setSelectedCountry,
    pronouns: selectedPronouns,
    setPronounce: setSelectedPronouns,
    birthdate: birthYear,
    setBirthdate: setBirthYear,
  } = useContext(RegistrationContext);

  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
  };

  const handleBirthYearChange = (event) => {
    setBirthYear(event.target.value);
  };
  return (
    <Box className={styles.form}>
      {/* this dialog only show when user is underage */}
      <UnderageDialog
        isOpen={isUnderageDialogeOpen}
        setIsOpen={setIsUnderageDialogeOpen}
      />
      <Box
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey400,
          p: "1.5rem",
        }}
      >
        <Typography
          variant="body1"
          color={theme.palette.darkgrey.darkgrey600}
          sx={{
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          Give us a few basic details about yourself:
        </Typography>
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Autocomplete
            sx={{
              bgcolor: theme.palette.lightgrey.lightgrey00,
              width: {
                //md: "50%",
              },
            }}
            fullWidth
            options={countriesNames}
            getOptionLabel={(option) => option}
            value={selectedCountry}
            onChange={handleCountryChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Country"
                variant="outlined"
              />
            )}
          />
          <TextField
            label="Postal Code"
            variant="outlined"
            //fullWidth
            value={postalCode}
            onChange={handlePostalCodeChange}
            sx={{
              backgroundColor: !darkMode && "#fff",
              width: {
                //md: "50%",
              },
              width: "50%",
            }}
          />

          <TextField
            variant="outlined"
            type="text"
            label="Birth Date"
            placeholder="MM-DD-YYY"
            fullWidth
            value={birthYear}
            onChange={handleBirthYearChange}
            sx={{
              backgroundColor: !darkMode && "#fff",
              width: {
                //md: "50%",
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              sx={{ color: theme.palette.darkgrey.darkgrey600 }}
            >
              Choose your pronouns
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{
                color: theme.palette.darkgrey.darkgrey500,
                fontSize: "14px",
              }}
              onChange={handlePronounsChange}
            >
              <FormControlLabel
                value="He/Him"
                control={<Radio />}
                label="He/Him"
              />
              <FormControlLabel
                value="She/Her"
                control={<Radio />}
                label="She/Her"
              />
              <FormControlLabel
                value="They/Them"
                control={<Radio />}
                label="They/Them"
              />
            </RadioGroup>
          </Box>
        </Box>
      </Box>
      <Box sx={{ p: "1.5rem" }}>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          endIcon={<ChevronRight />}
          className={styles.next}
          sx={{
            p: "8px 13px 8px 24px",
            justifyContent: "space-between",
            "& span:nth-of-type(1)": {
              background: theme.palette.primary.purpel400,
              height: "2em",
              width: "2em",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
            },
          }}
          onClick={handleSubmit}
        >
          Next Step
        </Button>
      </Box>
    </Box>
  );
};

export default Step3;
