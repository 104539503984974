import React, { useRef, useState, useEffect, useContext } from "react";

import "cropperjs/dist/cropper.min.css";
import { Box, Button, Typography } from "@mui/material";
import styles from "./style.module.css";
import supabase from "../../../../../../config/supabaseClient";
import { SkillImageContext } from "../../../../../../context/skill-image-context";
import { SkillsBarContext } from "../../../../../../context/skills-bar-context";
import { useTheme } from "../../../../../../context/theme-context";

const DeleteSkillImage = ({ modal }) => {
  const { theme } = useTheme();
  const { setSelectedSkillImage, setIsDeleteSkillImageOpen, croppedImage } =
    useContext(SkillImageContext);
  const { activeSkill, setActiveSkill } = useContext(SkillsBarContext);

  const original_cover_image = activeSkill?.original_cover_image;
  const handleDelete = async () => {
    try {
      setIsDeleteSkillImageOpen(false);
      setSelectedSkillImage(null);
      const { error, data } = await supabase
        .from("creator_skills")
        .update({
          skill_cover_image: null,
          original_cover_image: null,
        })
        .eq("id", activeSkill.id);

      if (error) {
        console.error("Error uploading skill image:", error);
      } else {
        // Handle any additional logic after a successful upload
      }
      const { data: updatedSkill, error: updatedSkillError } = await supabase
        .from("creator_skills")
        .select("*")
        .eq("id", activeSkill.id);
      setActiveSkill(updatedSkill[0]);
    } catch (error) {
      console.error("Error handling upload:", error);
    }
  };
  const handleCancel = () => {
    setIsDeleteSkillImageOpen(false);
    setSelectedSkillImage(null);
  };
  return (
    <Box className={styles.parent}>
      <Box
        className={styles.container}
        sx={{
          backgroundImage: `url(${original_cover_image})`,
          width: {
            xs: "100%",
            md: "400px",
          },
        }}
      >
        <Box className={styles.frame}>
          <Box className={styles.content}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleDelete}
              sx={{
                textTransform: "capitalize",
                bgcolor: "#fff",
                color: theme.palette.danger.red500,
                fontWeight: 500,
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCancel}
              sx={{
                textTransform: "capitalize",
                bgcolor: "#fff",
                color: theme.palette.secondary.dark,
                fontWeight: 500,
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteSkillImage;
