import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Chip, Link } from "@mui/material";
import styles from "./styles.module.css";
import { EmojiEvents } from "@mui/icons-material";
import img from "../../../../../../assets/images/primarySkills.png";
import supabase from "../../../../../../config/supabaseClient";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../../../../context/user-context";
import Info from "../../../../../../components/info";
import { defaultSkillImage } from "../../../../../../constants";
import { useTheme } from "../../../../../../context/theme-context";
import { useInfoBoxs } from "../../../../../../context/info-boxs-context";

const SkillCoverImage = ({ skill, fetchSkills, setActiveSkill }) => {
  //  skill.editorChoice = true;
  //skill.pro = true;
  const { infoBoxes } = useInfoBoxs();
  const { theme } = useTheme();
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const coverImage = skill?.skill_cover_image ? skill.skill_cover_image : defaultSkillImage;
  const [nomination, setNomination] = useState([]);
  useEffect(() => {
    if (nomination.length > 0) skill.is_nominated = true;
    else skill.is_nominated = false;
  }, [nomination]);
  useEffect(() => {
    const fetchNominations = async () => {
      const { data, error } = await supabase
        .from("skills_nominations")
        .select()
        .match({ skill_id: skill.id, nominator_id: user.id });
      setNomination(data);
      if (error) fetchNominations();
    };
    fetchNominations();
  }, [id, user, skill]);
  const handleNominate = async () => {
    if (nomination.length > 0) return;
    if (id === user?.id) return;
    await supabase
      .from("skills_nominations")
      .insert({ skill_id: skill.id, nominator_id: user.id });
    setNomination([{ skill_id: skill.id, nominator_id: user.id }]);

    // await fetchSkills();
    // setActiveSkill((skill) => ({ ...skill, is_nominated: user.id }));
  };

  return (
    <Box className={styles.skillsImg}>
      <Box className={styles.tags}>
        {skill.editorChoice && (
          <Button
            variant="contained"
            startIcon={<EmojiEvents />}
            sx={{ borderRadius: "25px" }}
          >
            EDITOR’S CHOICE
          </Button>
        )}
        <Box className={styles.nominate}>
          <Button
            variant="contained"
            onClick={handleNominate}
            sx={{
              textTransform: "capitalize",
              bgcolor:
                  nomination.length > 0
                  ? theme.palette.darkgrey.darkgrey300
                  : theme.palette.secondary.dark,
              color: '#FFF',
              height: "32px",
              fontSize: '16px',
              fontWeight: '600',
              padding: '0px 12px',
            }}
          >
            {nomination.length > 0 ? "Nominated" : "Nominate"}
          </Button>
          {infoBoxes.map((item) => {
            if (item.name === "nominate") {    
              return <Info
                heading={item.title}
                content={item.content}
              />
            }
          })}
          </Box>
        {skill.accept_only_paid_project && (
          <>
          <Chip
            label="Pro"
            sx={{
              bgcolor: theme.palette.tertiary.golden500,
              color: "# FFF",
            }}
          />
            {infoBoxes.map((item) => {
              if (item.name === 'pro') {    
                return <Info
                  heading={item.title}
                  content={item.content}
                />
              }
            })}
          </>
        )}
      </Box>
      <Box
        className={styles.primaryImg}
        sx={{
          backgroundImage: `url(${coverImage})`,
        }}
      >
        <Box className={styles.options}>
          {skill.is_primary && (
            <Chip
              label="Primary Skill"
              sx={{
                bgcolor: theme.palette.warning.main,
                color: '#FFF',
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SkillCoverImage;
