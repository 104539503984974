import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import UseLocalStorageState from "../../../hooks/userLocalStorage";
import profilePicture from "../../../assets/images/image-plus.svg";
import ProfileTabs from "./profile-tabs";
import ProfileImage from "./profile-image";
import DisplayTabs from "./profile-tabs/display-tabs";
import ProfileName from "./profile-name";
import { UserContext } from "../../../context/user-context";
import UploadProfileImage from "./profile-image/upload-profile-image";
import { ProfileImageContext } from "../../../context/profile-image-context";
import EditProfileImage from "./profile-image/edit";
import DeleteProfileImage from "./profile-image/delete";
import { SkillImageContext } from "../../../context/skill-image-context";
import EditSkillImage from "../my-skills/components/skill-cover-image.js/edit";
import DeleteSkillImage from "../my-skills/components/skill-cover-image.js/delete";
import UploadSkillImage from "../my-skills/components/skill-cover-image.js/upload-profile-image";
import { SkillsBarContext } from "../../../context/skills-bar-context";
import { LoadingContext } from "../../../context/loading-context";
import Loading from "../../../components/loading/default";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../context/theme-context";

const MyProfile = () => {
  const { theme, darkMode } = useTheme();
  const { selectedImage, isModalOpen, setIsModalOpen, isDeleteOpen } =
    useContext(ProfileImageContext);
  const {
    selectedSkillImage,
    isModalSkillOpen,
    setIsModalSkillOpen,
    isDeleteSkillImageOpen,
  } = useContext(SkillImageContext);
  const [value, setValue] = UseLocalStorageState("myprofile-bar", 1);
  const { user } = useContext(UserContext);
  const { activeSkill, setActiveSkill } = useContext(SkillsBarContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  console.log(user, "from user");
  useEffect(() => {}, [value]);
  useEffect(() => {}, [user]);
  useEffect(() => {
    const redirectedUrl = localStorage.getItem("redirectedUrl");
    if (redirectedUrl?.startsWith("/creators/")) {
      localStorage.removeItem("redirectedUrl");
      navigate(redirectedUrl);
    }
  }, [user]);
  let profile_image = user?.profile_image;
  useEffect(() => {
    profile_image = user?.profile_image;
  }, [user]);

  let skill_cover_image = activeSkill?.skill_cover_image;
  useEffect(() => {
    skill_cover_image = activeSkill?.skill_cover_image;
  }, [activeSkill]);

  const data = {
    value,
    setValue,
  };
  const modal = {
    isModalOpen,
    setIsModalOpen,
    profile_image,
  };
  const pImageData = {
    profile_image,
    setIsModalOpen,
  };

  const SkillImageModal = {
    isModalSkillOpen,
    setIsModalSkillOpen,
    skill_cover_image,
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <Loading />;
  }

  return (
    <Box className={styles.parent}>
      <Box
        className={styles.cardDetails}
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey600,
        }}
      >
        {selectedImage && <EditProfileImage />}
        {isDeleteOpen && <DeleteProfileImage />}
        {selectedSkillImage && (
          <EditSkillImage
            activeSkill={activeSkill}
            setActiveSkill={setActiveSkill}
          />
        )}
        {isDeleteSkillImageOpen && <DeleteSkillImage />}
        <Box className={styles.myCardDetails}>
          <Box className={styles.userDetails}>
            <Box className={styles.details}>
              <ProfileImage data={pImageData} />
              <ProfileName />
            </Box>
          </Box>
          <ProfileTabs data={data} />
        </Box>
        <DisplayTabs value={value} />
      </Box>
      <UploadProfileImage modal={modal} />
      <UploadSkillImage modal={SkillImageModal} />
    </Box>
  );
};

export default MyProfile;
