import { Box, Button, Divider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import Filter from "./filters";
import ResponsiveCarousel from "./Cards";
import { ChevronLeft } from "@mui/icons-material";
import { SearchContext } from "../../../context/search-context";
import supabase from "../../../config/supabaseClient";
import { useTheme } from "../../../context/theme-context";
const SearchResult = () => {
  const [searchResults, setSearchResults] = useState(null);
  const { theme } = useTheme();
  const navigate = useNavigate();
  const {
    searchKeyword,
    searchType,
    selectedSkill,
    selectedSpecialty,
    category,
    selectedCountry,
    selectedState,
    selectedEditorChoice,
    selectedProsOnly,
    selectedAgeRagnes,
  } = useContext(SearchContext);

  useEffect(() => {
    const fetchKeywordSearch = async () => {
      const { data, error } = await supabase
        .from("users_primary_skills")
        .select();
      // .filter(
      //   "tagline",
      //   "ilike",
      //   `%${searchKeyword}%`,
      //   "OR",
      //   "real_name",
      //   "ilike",
      //   `%${searchKeyword}%`
      // );

      setSearchResults(
        data.map((item) => {
          return {
            profileImage: item.profile_image,
            nickname: item.nick_name,
            name: item.real_name,
            primaryImage: item.skill_cover_image,
            skill: item.description,
            user_id: item.user_id,
          };
        })
      );
    };
    const fetchCustomSearchResults = async () => {
      const { data, error } = await supabase
        .from("users_types_skills")
        .select();
      // .match({ category, skill: selectedSkill, country: selectedCountry });

      setSearchResults(
        data.map((item) => {
          return {
            profileImage: item.profile_image,
            nickname: item.nick_name,
            name: item.real_name,
            primaryImage: item.skill_cover_image,
            skill: item.description,
            user_id: item.user_id,
          };
        })
      );
    };
    if (searchType === "singleSearch") fetchKeywordSearch();
    if (searchType === "custom") fetchCustomSearchResults();
  }, []);
  return (
    <Box
      className={styles.result}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey500,
        minHeight: "100vh",
        paddingBottom: "4rem",
      }}
    >
      <Box
        className={styles.container}
        sx={{
          width: {
            xs: "100%",
          },
        }}
      >
        <Box className={styles.arrow}>
          <Button
            variant="text"
            startIcon={<ChevronLeft />}
            onClick={()=> navigate("/search") }
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
              textTransform: "capitalize",
              p: "0px",
              fontSize: '14px',
              fontWeight: '400'
            }}
          >
            Search
          </Button>
        </Box>
        <Filter />
        <Divider></Divider>
        <ResponsiveCarousel results={searchResults} />
      </Box>
    </Box>
  );
};

export default SearchResult;
