import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import AboutMe from "../../../About-Me";
import MySkills from "../../../my-skills";

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import ShoutOuts from "../../../../../components/shout-outs/shoutouts";
import { UserContext } from "../../../../../context/user-context";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [user, isAuthenticated]);
  if (!user) return <div>loading...</div>;
  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              width: {
                xs: "100%",
                // md: "50vw",
              },
            }}
          >
            {children}
          </Box>
        )}
      </div>
    </>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DisplayTabs = ({ value }) => {
  const { user } = useContext(UserContext);
  return (
    <Box className={styles.displayTabs}>
      <CustomTabPanel value={value} index={0}>
        <AboutMe />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <MySkills />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ShoutOuts user={user} />
      </CustomTabPanel>
    </Box>
  );
};

export default DisplayTabs;
