import { Box, Button, Chip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import { Clear, ClearRounded } from "@mui/icons-material";
import { SearchContext } from "../../../../context/search-context";
import { useTheme } from "../../../../context/theme-context";

const Filter = () => {
  const [filters, setFilters] = useState([
    "Category",
    "Skills",
    "Specialty",
    "Age",
    "Location",
    "State",
  ]);
  const {
    searchKeyword,
    searchType,
    selectedSkill,
    selectedSpecialty,
    category,
    selectedCountry,
    selectedState,
    selectedEditorChoice,
    selectedProsOnly,
    selectedAgeRanges,
  } = useContext(SearchContext);
  const { theme, darkMode } = useTheme();

  useEffect(() => {
    if (searchType === "custom") {
      setFilters([
        category,
        selectedSkill,
        selectedSpecialty,
        selectedCountry,
        ...selectedAgeRanges,
      ]);
    } else {
      setFilters(["Tagline || Creator Name"]);
    }
  }, [searchType, selectedSkill]);

  const handleDelete = (index) => {
    // Create a copy of the array without the deleted item
    const updatedFilters = [...filters];
    updatedFilters.splice(index, 1);

    // Update the state with the new array
    setFilters(updatedFilters);
  };
  const handleClear = () => {
    // clear all filters
  };
  const handleClick = () => {};

  return (
    <Box className={styles.filter}>
      {filters.map((item, index) => (
        <Chip
          label={item}
          onClick={handleClick}
          onDelete={handleDelete}
          deleteIcon={<ClearRounded />}
          sx={{
            gap: '13px',
            pr: '8px'
          }}
        />
      ))}
      <Button
        variant="text"
        sx={{
          textTransform: "capitalize",
          fontSize: "14px",
          pl: "0",
          pr: "0",
          color: darkMode && theme.palette.primary.purpel100,
        }}
      >
        Re-Rank
      </Button>
      {/* <Button
        variant="text"
        onClick={handleClear}
        sx={{
          textTransform: "capitalize",
          fontSize: "14px",
          pl: "0",
          pr: "0",
          color: theme.palette.darkgrey.darkgrey300,
        }}
      >
        Clear Filters
      </Button> */}
    </Box>
  );
};

export default Filter;
