import React, { useRef, useState, useEffect, useContext } from "react";

import "cropperjs/dist/cropper.min.css";
import { Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import { UserContext } from "../../../../../context/user-context";
import ImageCropper from "./cropper";
import { ProfileImageContext } from "../../../../../context/profile-image-context";
import { useNavigate } from "react-router-dom";
import supabase from "../../../../../config/supabaseClient";
import { uploadImage } from "../../../../../helpers/uploadImageToS3";
import { LoadingContext } from "../../../../../context/loading-context";
import imageCompression from "browser-image-compression";

const EditProfileImage = ({ modal }) => {
  const { user, setUser } = useContext(UserContext);
  const {
    selectedImage,
    setSelectedImage,
    croppedImage,
    setCroppedImage,
    setIsModalOpen,
  } = useContext(ProfileImageContext);
  const [isProfileUploading, setIsProfileUploading] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const handleCrop = async (croppedImage) => {
    try {
      // Convert the Uint8Array to a Blob
      const blob = new Blob([croppedImage], { type: "image/png" });
      const originalBlob = blob;
      
      // Compress the image using browser-image-compression library
      const options = {
        maxSizeMB: 0.5, // Set the maximum size of the compressed image to 0.5 MB
        maxWidthOrHeight: 400, // Set the maximum width or height of the compressed image
        useWebWorker: true, // Use a web worker for faster compression
        quality: 0.7, // Adjust the quality parameter (0.7 is a good starting point)
      };

      const compressedBlob = await imageCompression(originalBlob, options);
      console.log(compressedBlob, 'compressedBlob from cropped');

      const blobURL = await uploadImage(
        compressedBlob,
        "profile-picture-user-" + user.id + "-v-" + new Date().getTime()
      );
      setCroppedImage(blobURL);
      setIsModalOpen(false);
      setSelectedImage(null);
      setIsLoading(true);

      const { error, data } = await supabase
        .from("users")
        .update({
          profile_image: blobURL,
        })
        .eq("id", user.id);

      if (error) {
        console.error("Error uploading profile image:", error);
      } else {
        // Handle any additional logic after a successful upload
      }
      const { data: updatedUser, error: updatedUserError } = await supabase
        .from("users")
        .select("*")
        .eq("email", user.email);
      
      setUser(updatedUser[0]);
      setIsLoading(false);
      
      // handle original image uploade
      if (selectedImage !== user?.original_image) {
        const response = await fetch(selectedImage);
        const blobData = await response.blob();
        
        // Compress the image using browser-image-compression library
        const options = {
          maxSizeMB: 1, // Set the maximum size of the compressed image
          maxWidthOrHeight: 800, // Set the maximum width or height of the compressed image
          useWebWorker: true, // Use a web worker for faster compression
          quality: 0.7,
        };

        const compressedBlob = await imageCompression(blobData, options);

        const originalImageUrl = await uploadImage(
          compressedBlob,
          "original-image-user-" + user.id + "-v-" + new Date().getTime()
        );

        const { error, dataResponse } = await supabase
          .from("users")
          .update({
            original_image: originalImageUrl,
          })
          .eq("id", user.id);
        
        const { data: updatedUser, error: updatedUserError } = await supabase
          .from("users")
          .select("*")
          .eq("email", user.email);
        
        setUser(updatedUser[0]);
      }
      
    } catch (error) {
      console.error("Error handling upload:", error);
    }
  
  };
  const handleCancel = () => {
    setCroppedImage(null);
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const profile_image = user?.profile_image;
  const imageSrc = selectedImage || profile_image;

  return (
    <Box className={styles.parent}>
      <Box className={styles.container}>
        <ImageCropper
          imageSrc={selectedImage}
          onCrop={handleCrop}
          onCancel={handleCancel}
          isProfileUploading={isProfileUploading}
          setIsProfileUploading={setIsProfileUploading}
        />
      </Box>
    </Box>
  );
};

export default EditProfileImage;
