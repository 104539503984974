import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { PostAddRounded } from "@mui/icons-material";
import styles from "./style.module.css";
import Given from "./given";
import Received from "./received";
import supabase from "../../../config/supabaseClient";
import { UserContext } from "../../../context/user-context";
import { LoadingContext } from "../../../context/loading-context";
import LoadingButton from "../../loading/button";
import NoShoutOuts from "../components/NoShoutOuts";

const Text = ({ showReceived, showGiven, user }) => {
  const [receivedShoutOuts, setReceivedShoutOuts] = useState([]);
  const [givenShoutOuts, setGivenShoutOuts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchGivenShoutOuts = async () => {
    setIsLoading(true);
    const { data, error } = await supabase
      .from("given_shoutouts")
      .select()
      .eq("sender_id", user?.id);

    setGivenShoutOuts(data);

    setIsLoading(false);
  };
  useEffect(() => {
    const fetchReceivedShoutOuts = async () => {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("received_shoutouts")
        .select()
        .eq("receiver_id", user?.id);
      setReceivedShoutOuts(data);
      setIsLoading(false);
    };

    fetchReceivedShoutOuts();
    fetchGivenShoutOuts();
    console.log(receivedShoutOuts, 'testRecived shout outs');
  }, [user]);
  // useEffect(() => {
  //   if (showGiven) {
  //     fetchGivenShoutOuts();
  //   }
  // }, [showGiven, user]);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <LoadingButton />
      </div>
    );
  }

  if (receivedShoutOuts.length === 0 && givenShoutOuts.length === 0) {
    return (
      <NoShoutOuts />
    );
  }

  return (
    <Box className={styles.card}>
      {showGiven &&
        givenShoutOuts.map((item, index) => (
          <Box key={index} className={styles.container}>
            {<Given data={item} />}{" "}
          </Box>
        ))}
      {showReceived &&
        receivedShoutOuts?.map((user, index) => (
          <Box key={index} className={styles.container}>
            {showReceived && <Received data={user} />}
          </Box>
        ))}
    </Box>
  );
};

export default Text;
