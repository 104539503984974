import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  Button,
  RadioGroup,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  FormGroup,
  Autocomplete,
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import styles from "./aboutEdit.module.css";

import { UserContext } from "../../../context/user-context";
import useCountries from "../../../helpers/getAllCountries";

import supabase from "../../../config/supabaseClient";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../../../context/loading-context";
import LoadingButton from "../../../components/loading/button";
import Info from "../../../components/info";
import { useTheme } from "../../../context/theme-context";
import { ProfileCompleteContext } from "../../../context/profile-complete-context";

const Gens = ["X", "X+", "Y", "Y+", "Z", "Z+"];

const AboutEdit = () => {
  const { collaborator, setCollaborator } = useContext(ProfileCompleteContext);
  const { theme, darkMode } = useTheme();
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [realName, setRealName] = useState("");
  const [nickName, setNickName] = useState("");
  const [tagline, setTagline] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [publicEmailAddress, setPublicEmailAddress] = useState("");
  const [registeredEmailAddress, setRegisteredEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [pronounce, setPronounce] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const [error, setError] = useState(null);
  const [collaboratorsTypes, setCollaboratorsTypes] = useState([]);
  const [county, setCounty] = useState("");
  const [havePrimarySkill, setHavePrimarySkill] = useState(false);

  const [selectedCollaboratorsTypes, setSelectedCollaboratorsTypes] = useState(
    []
  );
  const [ageRanges, setAgeRanges] = useState([]);
  const { countriesNames } = useCountries();
  useEffect(() => {
    if (!user) return;
    setSelectedCountry(user.country);
    setRealName(user.real_name);
    setNickName(user.nick_name);
    setTagline(user.tagline);
    setPostalCode(user.postal_code);
    setPublicEmailAddress(user.public_email);
    setRegisteredEmailAddress(user.email);
    setPhoneNumber(user.mobile);
    setBirthYear(moment(user.birth_date).format("MM-DD-YYYY"));
    setPronounce(user.pronouns);
    setState(user.state);
    setCounty(user.county);
    setCity(user.city);
  }, [user]);
  const [isUpdatingInfo, setIsUpdatingInfo] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const handleDesiredCollaboratorToggle = (value) => () => {
    const itemIndex = selectedCollaboratorsTypes.findIndex(
      (item) => item.category === value.category
    );
    
    if (itemIndex === -1) {
      return setSelectedCollaboratorsTypes((items) => [...items, value]);
    }
    // item exists remove it
    const filteredItems = selectedCollaboratorsTypes.filter(
      (item) => item.category !== value.category
    );
    setSelectedCollaboratorsTypes([...filteredItems]);
  };

  useEffect(() => {
    const fetchCollaboratorsTypes = async () => {
      const { error, data } = await supabase
        .from("registeration_collaborator_types")
        .select("*");
      if (error) {
        setError(error);
      }

      setCollaboratorsTypes(data);
    };
    const fetchUserCollaboratorsTypes = async () => {
      const { data, error } = await supabase
        .from("creator_desired_collaborators")
        .select("*")
        .eq("user_id", user?.id);
      
      setSelectedCollaboratorsTypes(data);
    };

    const fetchAgeRanges = async () => {
      const { data, error } = await supabase
        .from("collaborat_age_ranges")
        .select("*")
        .eq("user_id", user.id);

      setAgeRanges(data?.map((item) => item.age_category) || []);
    };
    if (user) {
      fetchUserCollaboratorsTypes();
      fetchCollaboratorsTypes();
      fetchAgeRanges();
    }
  }, [user]);
  const handleAgeRangesChange = (range) => {
    const index = ageRanges.findIndex((item) => item === range);
    if (index === -1) {
      setAgeRanges([...ageRanges, range]);
    } else {
      setAgeRanges([
        ...ageRanges.filter((item, itemIndex) => itemIndex !== index),
      ]);
    }
  };

  // this function check if user have primary skill which help in profile completion  
  const checkUserHavePrimarySkill = async() => {
    const { data: primarySkill, error } = await supabase
    .from("creator_skills")
    .select("*")
    .eq("user_id", user.id)
    .eq("is_primary", true);
    
    return primarySkill.length > 0 ? true : false;
  }
  useEffect(() => {
    const havePrimary = checkUserHavePrimarySkill();
    setHavePrimarySkill(havePrimary);
  }, [])
  
  const handleSave = async () => {
    setIsUpdatingInfo(true);
    setIsLoading(true);
    await supabase
      .from("collaborat_age_ranges")
      .delete()
      .eq("user_id", user.id);
    await supabase
      .from("creator_desired_collaborators")
      .delete()
      .eq("user_id", user.id);
    for (let i = 0; i < selectedCollaboratorsTypes.length; i++) {
      const item = selectedCollaboratorsTypes[i];
      const record = {
        type: item.creator_type || item.type,
        category: item.category,
        user_id: user.id,
        display_name: item.collaborator_category || item.display_name,
      };

      const { error: collaboratorsError } = await supabase
        .from("creator_desired_collaborators")
        .insert(record);
    }

    /// for handling profile complete 
    if (selectedCollaboratorsTypes.length > 0 && !collaborator) {
      setCollaborator(true);

    }else if (selectedCollaboratorsTypes.length === 0 && collaborator) {
      setCollaborator(false)

    }
    ///////
    for (let i = 0; i < ageRanges.length; i++) {
      const item = ageRanges[i];
      await supabase
        .from("collaborat_age_ranges")
        .insert({ user_id: user.id, age_category: item });
    }

    const { data, error } = await supabase
      .from("users")
      .update({
        real_name: realName,
        nick_name: nickName,
        country: selectedCountry,
        tagline,
        postal_code: postalCode,
        public_email: publicEmailAddress,
        email: registeredEmailAddress,
        mobile: phoneNumber,
        birth_date: moment(birthYear, "MM-DD-YYYY").format("YYYY-MM-DD"),
        pronouns: pronounce,
        last_record_change: moment().format("YYYY-MM-DD"),
        city,
        county,
        state,
      })
      .eq("id", user.id);

    const { data: updatedUser, error: updatedUserError } = await supabase
      .from("users")
      .select("*")
      .eq("id", user.id);

    // check user profile complete
    const allAccessMember =
      havePrimarySkill &&
      updatedUser[0].real_name &&
      updatedUser[0].profile_image &&
      updatedUser[0].tagline &&
      updatedUser[0].mobile &&
      updatedUser[0].birth_date &&
      updatedUser[0].country &&
      updatedUser[0].public_email;
    
    // update in complete status  
    if (allAccessMember) {
      const { error: inCompleteStatusError } = await supabase
        .from("user_community_status")
        .update({
          status: "All Access Member",
          date_acquired: moment().format("YYYY-MM-DD"),
        })
        .eq("user_id", user.id)
        .eq("status", "Incomplete");
      
    } 

    setUser(updatedUser[0]);
    navigate("/profile");
    setIsLoading(false);
  };

  useEffect(() => {
    // Scroll to the top of the component
    window.scrollTo(0, 0);
  }, []);

  if (!collaboratorsTypes || !ageRanges) return <div>loading</div>;

  const handleCancel = () => {
    navigate("/profile");
  }

  return (
    <Box className={styles.dialog}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <Box className={styles.communityStatus}>
          <Box className={styles.details}>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.darkgrey.darkgrey600,
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Personal Details
            </Typography>
          </Box>
          <Box className={styles.content}>
            <TextField
              label="My Real Name"
              name="name"
              value={realName}
              onChange={(e) => setRealName(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{}}
            />
            <TextField
              label="My Nickname"
              name="name"
              value={nickName}
              onChange={(e) => setNickName(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{}}
            />
            <TextField
              label="Tagline"
              name="name"
              value={tagline}
              onChange={(e) => setTagline(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{}}
            />
            <TextField
              label="Public Email Address"
              name="name"
              value={publicEmailAddress}
              onChange={(e) => setPublicEmailAddress(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{}}
            />
            <TextField
              label="Registered Email Address"
              name="name"
              value={registeredEmailAddress}
              onChange={(e) => setRegisteredEmailAddress(e.target.value)}
              variant="outlined"
              disabled
              fullWidth
              sx={{}}
            />
            <TextField
              label="Phone Number"
              name="phone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{}}
            />
            <TextField
              label="Birth Date"
              variant="outlined"
              type="text"
              fullWidth
              placeholder="MM-DD-YYYY"
              value={birthYear}
              onChange={(e) => setBirthYear(e.target.value)}
              sx={{}}
              InputProps={{
                endAdornment: <InputAdornment position="end"></InputAdornment>,
              }}
            />
            <Autocomplete
              sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
              fullWidth
              options={countriesNames}
              getOptionLabel={(option) => option}
              value={selectedCountry}
              onChange={(e, value) => setSelectedCountry(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Country"
                  variant="outlined"
                />
              )}
            />
            <TextField
              label="Postal Code"
              name="name"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{}}
            />
            <TextField
              label="City"
              name="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{}}
            />
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                alignSelf: "stretch",
              }}
            >
              <TextField
                label="State/Province"
                name="name"
                value={state}
                onChange={(e) => setState(e.target.value)}
                variant="outlined"
                fullWidth
                sx={{}}
              />
              <TextField
                label="County/Territory"
                name="county"
                value={county}
                onChange={(e) => setCounty(e.target.value)}
                variant="outlined"
                fullWidth
                sx={{}}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{
                  color: darkMode
                    ? "#fff"
                    : theme.palette.darkgrey.darkgrey500,
                }}
              >
                Choose your pronouns
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{
                  color: theme.palette.darkgrey.darkgrey500,
                  fontSize: "14px",
                }}
                value={pronounce}
                onChange={(e) => setPronounce(e.target.value)}
              >
                <FormControlLabel
                  value="He/Him"
                  control={<Radio />}
                  label="He/Him"
                />
                <FormControlLabel
                  value="She/Her"
                  control={<Radio />}
                  label="She/Her"
                />
                <FormControlLabel
                  value="They/Them"
                  control={<Radio />}
                  label="They/Them"
                />
              </RadioGroup>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box
          className={styles.basicsType}
          sx={{
            "& .MuiList-padding": {
              p: "0px",
            },
            "& .MuiList-root ": {
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            },
          }}
        >
          <Typography
            variant="body1"
            color={theme.palette.darkgrey.darkgrey600}
            sx={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            {" "}
            I am looking to collaborate with:
          </Typography>
          <Box
            className={styles.form}
            sx={{
              "& .css-cveggr-MuiListItemIcon-root": {
                minWidth: "5px",
              },
              "& .css-118ob9i-MuiButtonBase-root-MuiListItemButton-root": {
                p: "0px",
              },
            }}
          >
            <List sx={{
              width: "100%", mb: "24px", mt: "24px", m: 0,
              color: theme.palette.darkgrey.darkgrey500
            }}>
              {collaboratorsTypes.map((type) => {
                const labelId = `checkbox-list-label-${type.category}`;
                return (
                  <ListItem
                    key={type.category}
                    disablePadding
                    sx={{ padding: 0 }}
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleDesiredCollaboratorToggle(type)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={
                            selectedCollaboratorsTypes.findIndex(
                              (item) => item.category === type.category
                            ) !== -1
                          }
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={`${type.collaborator_description}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Typography
              variant="body1"
              color={theme.palette.darkgrey.darkgrey600}
              sx={{
                fontWeight: "500",
              }}
            >
              Age Range
            </Typography>
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                "& .css-ahj2mt-MuiTypography-root": {
                  fontSize: "14px",
                },
              }}
            >
              {Gens.map((Gen, index) => (
                <Box
                  key={index}
                  className={styles.ageGen}
                  sx={{
                    "& .css-j204z7-MuiFormControlLabel-root": {
                      marginRight: "0",
                    },
                    "& .css-onpov0-MuiButtonBase-root-MuiCheckbox-root": {
                      padding: "0px 8px 0px 9px",
                    },
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox size="small" />}
                    defaultChecked={
                      ageRanges.findIndex((item) => item === Gen) === -1
                        ? false
                        : true
                    }
                    checked={
                      ageRanges.findIndex((item) => item === Gen) === -1
                        ? false
                        : true
                    }
                    onChange={() => handleAgeRangesChange(Gen)}
                    label={`Gen ${Gen}`}
                    sx={{
                      color: theme.palette.darkgrey.darkgrey500,
                    }}
                  />
                  <Info heading={`Gen ${Gen}`}/>
                </Box>
              ))}
            </FormGroup>
          </Box>
          <Box className={styles.buttons}>
            <Button
              onClick={handleSave}
              variant="contained"
              disabled={isUpdatingInfo}
              sx={{
                width: {
                  xs: "100%",
                },
              }}
            >
              {isLoading ? <LoadingButton /> : "Save"}
            </Button>
            <Button
              onClick={handleCancel}
              variant="text"
              sx={{
                color: theme.palette.darkgrey.darkgrey400,
                p: '0px',
                textTransform: "capitalize",
                fontSize: '16px',
                fontWeight: '500',
                width: {
                  xs: "100%",
                },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutEdit;
