import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import styles from "./style.module.css";
import ProfileTabs from "./tabs";
import rockAndRoll from "../../../assets/images/rock-and-roll.png";
import supabase from "../../../config/supabaseClient";
import { UserContext } from "../../../context/user-context";
import { useParams } from "react-router-dom";
import gradientImage from "../../../assets/images/gradient.png";
import { useTheme } from "../../../context/theme-context";

const Header = ({ data, profilImage, realName, nickName }) => {
  const { theme } = useTheme();
  const [gaveShout, setGaveShoutOut] = useState(false);
  const params = useParams();
  const { user } = useContext(UserContext);
  const [flags, setFlags] = useState([]);
  useEffect(() => {
    const fetchShoutOuts = async () => {
      const { data, error } = await supabase
        .from("user_shoutouts")
        .select()
        .match({ receiver_id: params.id, sender_id: user?.id });
      if (data?.length > 0) {
        setGaveShoutOut(true);
      }
    };
    const fetchFlags = async () => {
      const { data, error } = await supabase
        .from("user_flags")
        .select()
        .match({ reporter_id: user?.id, reported_id: params.id });
      if (error) fetchFlags();
      setFlags(data);
    };
    if (user?.id) {
      fetchShoutOuts();
      fetchFlags();
    }
  }, [user, params]);
  const handleShoutOutClick = async () => {
    if (gaveShout) {
      cancelShoutOut();
      return;
    }
    const { data, error } = await supabase
      .from("user_shoutouts")
      .insert({ receiver_id: params.id, sender_id: user?.id });
    if (!error) setGaveShoutOut(true);
  };
  const cancelShoutOut = async () => {
    if (gaveShout) {
      const { data, error } = await supabase
        .from("user_shoutouts")
        .delete()
        .match({ receiver_id: params.id, sender_id: user?.id });
      if (!error) {
        setGaveShoutOut(false);
      }
    }
  };
  const handleFlagClick = async () => {
    if (flags.length > 0) {
      // await supabase
      //   .from("user_flags")
      //   .delete()
      //   .match({ reporter_id: user?.id, reported_id: params.id });
      // setFlags([]);
    } else {
      await supabase
        .from("user_flags")
        .insert({ reporter_id: user?.id, reported_id: params.id });
      setFlags([{ reporter_id: user?.id, reported_id: params.id }]);
    }
  };
  return (
    <React.Fragment>
      <Box
        className={styles.myCardDetails}
        style={{ backgroundImage: `url(${gradientImage})` }}
      >
        <Box className={styles.userDetails}>
          <Box className={styles.details}>
            <Box className={styles.image__parent}>
              <Box
                className={styles.image}
                sx={{
                  backgroundImage: `url(${profilImage})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}
              ></Box>
            </Box>

            <Box className={styles.name}>
              <Typography
                variant="body1"
                component="h6"
                sx={{
                  color: "#FFF",
                  lineHeight: '1rem',
                  fontSize: {
                    xs: "16px",
                    sm: "20px",
                  },
                }}
              >
                {realName}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.tertiary.golden500,
                }}
              >
                {nickName}
              </Typography>
              <Button
                style={{
                  color: theme.palette.darkgrey.darkgrey50,
                  backgroundColor: 'rgba(232, 225, 234, 0.24)',
                  textTransform: 'capitalize',
                  fontSize: '12px',
                  padding: '4px 12px',
                  fontWeight: '400',
                }}
                onClick={handleFlagClick}
              >
                {flags.length > 0 ? "flagged" : "Flag"}
              </Button>
            </Box>
          </Box>
          <Box
            className={styles.shouts}
            sx={{
              bgcolor: gaveShout
                ? theme.palette.warning.main
                : theme.palette.darkgrey.darkgrey400,
            }}
            onClick={handleShoutOutClick}
          >
            <img
              src={rockAndRoll}
              width="100%"
              height="100%"
              alt="rock-and-roll"
            />
          </Box>
        </Box>
        <ProfileTabs data={data} realName={realName?.split(" ")[0]} />
      </Box>
    </React.Fragment>
  );
};

export default Header;
