import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../context/theme-context";

const Image = ({ image, isPrimary, isEditorChoice }) => {
  const { theme } = useTheme();

  return (
    <Box className={styles.image}>
      <Box
        className={styles.primaryImg}
        style={{
          backgroundImage: `url(${
            image
              ? image
              : "../../../../../../assets/images/searchResultImage.png"
          })`,
        }}
      ></Box>
      <Box className={styles.options}>
        {isEditorChoice && (
          <Chip
            label="CREATOR'S CHOICE"
            sx={{
              bgcolor: theme.palette.warning.main,
              color: theme.palette.lightgrey.lightgrey00,
            }}
          />
        )}
        {isPrimary && (
          <Chip
            label="Primary Skill"
            sx={{
              bgcolor: theme.palette.success.dark,
              color: theme.palette.lightgrey.lightgrey00,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Image;
