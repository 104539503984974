import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { TaskOutlined } from "@mui/icons-material";
import styles from './style.module.css';
import { useNavigate } from "react-router-dom";
import fileCheckIcon from "../../../assets/images/file-check-02 (1).svg";
import { useTheme } from "../../../context/theme-context";

const JobSubmission = ({isOpen, setIsOpen}) => {
    const { theme } = useTheme();
    const navigate = useNavigate();
    
    const handleClose = () => {
        setIsOpen(false);
    };

    const handleClick = () => {
        navigate('/discover')
    }

    return (
        <Box>
            <Modal
                className={styles.jobSubmission}
                open={isOpen} onClose={handleClose}
            >            
                <Box className={styles.container}
                    sx={{
                        bgcolor: theme.palette.lightgrey.lightgrey00,
                        width: {
                            xs: '350px',
                            // sm: '75%',
                            // md: '50%',
                            // lg: '40%',
                        },
                        p: {
                            xs: '40px 12px'
                        }
                    }}
                >
                    <Box className={styles.icon}
                        sx={{
                            color: theme.palette.lightgrey.lightgrey00,
                        }}
                    >
                        <img src={fileCheckIcon} alt="file check" />
                        {/* <TaskOutlined style={{ fontSize: '88px',}} /> */}
                    </Box>
                    <Box className={styles.text}>
                        <Typography variant="h5" color={theme.palette.darkgrey.darkgrey700}
                            sx={{
                                textAlign: 'center',
                                fontWeight: '700',
                                fontSize: '20px',
                                lineHeight: '24px', 
                            }}
                        >
                        Your project submission has been received!
                        </Typography>
                        <Typography variant="body1"
                            color={theme.palette.darkgrey.darkgrey500}
                            sx={{
                                textAlign:'center'
                            }}
                        >
                            You will receive a confirmation by email.    
                        </Typography>
                    </Box>
                    <Button variant="contained"
                        onClick={handleClick}
                        fullWidth
                        sx={{
                            maxWidth: '300px'
                        }}
                    >
                        Discover Other Creators
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
}

export default JobSubmission;
