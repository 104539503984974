import moment from "moment";
import { createContext, useState } from "react";

export const RegistrationContext = createContext(null);
const RegistrationContextProvider = (props) => {
  const [periorityCode, setPeriorityCode] = useState("");
  const [creatorTypes, setCreatorTypes] = useState([]);
  const [collaboratorsTypes, setCollaboratorsTypes] = useState([]);
  const [country, setCountry] = useState("United States");
  const [postalCode, setPostalCode] = useState("");
  const [birthdate, setBirthdate] = useState("MM-DD-YYYY");
  const [pronounce, setPronounce] = useState("");
  const [completedInfo, setCompletedInfo] = useState(false);

  const contextValue = {
    completedInfo,
    setCompletedInfo,
    periorityCode,
    setPeriorityCode,
    creatorTypes,
    setCreatorTypes,
    country,
    setCountry,
    postalCode,
    setPostalCode,
    pronounce,
    setPronounce,
    birthdate,
    collaboratorsTypes,
    setCollaboratorsTypes,
    setBirthdate,
  };

  return (
    <RegistrationContext.Provider value={contextValue}>
      {props.children}
    </RegistrationContext.Provider>
  );
};
export default RegistrationContextProvider;
