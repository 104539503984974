import { Box, Typography, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import SkillStatus from "./components/status/index.js";
import AboutSkill from "./components/skill-about/index.js";
import SkillsBar from "./components/skills-bar/index.js";
import SkillCoverImage from "./components/skill-cover-image.js/index.js";
import { SkillsBarContext } from "../../../context/skills-bar-context.js";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/user-context.js";
import supabase from "../../../config/supabaseClient.js";
import DeleteConfirmation from "./components/delete-skill/index.js";
import { LoadingContext } from "../../../context/loading-context.js";
import Loading from "../../../components/loading/default";
import LoadingButton from "../../../components/loading/button/index.js";
import { useTheme } from "../../../context/theme-context";

const MySkills = () => {
  const { theme, darkMode } = useTheme();
  const navigate = useNavigate();
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [skills, setSkills] = useState([
    {
      created: false,
      name: "SKILL #0" + 1,
      category: "notCreated",
    },
    {
      created: false,
      name: "SKILL #0" + (1 + 1),
      category: "notCreated",
    },
    {
      created: false,
      name: "SKILL #0" + (2 + 1),
      category: "notCreated",
    },
  ]);

  const { user } = useContext(UserContext);
  const { setActiveSkill, activeSkill } = useContext(SkillsBarContext);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSkills = async () => {
    if (!user) return;
    setIsLoading(true);
    const { error, data } = await supabase
      .from("user_skills")
      .select("*")
      .eq("user_id", user.id);
    const updatedSkill = [];
    setIsLoading(false);

    for (let i = 0; i < 3; i++) {
      if (data[i]) {
        if (!activeSkill) {
          setActiveSkill({ ...data[i], creatred: true, name: data[i].name });
        }
        updatedSkill.push({ ...data[i], creatred: true, name: data[i].name });
        continue;
      }
      updatedSkill.push({
        id: Infinity,
        created: false,
        name: "SKILL #0" + (i + 1),
        category: "notCreated",
      });
    }
    setSkills(
      updatedSkill.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
    );
  };
  const handleDeleteSkillClick = async () => {
    await supabase.from("creator_skills").delete().eq("id", activeSkill.id);
    fetchSkills();
    setActiveSkill(null);
  };
  const handleDeleteAcount = () => {
    setIsOpenDelete(true);
  };

  useEffect(() => {
    fetchSkills();
    if (activeSkill && user) {
      if (+activeSkill.user_id !== +user.id) setActiveSkill(null);
    }
  }, [activeSkill]);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <LoadingButton />
      </div>
    );
  }

  if (!activeSkill) {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            padding: "16px 24px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
          }}
        >
          <SkillsBar userSkills={skills} />
        </Box>
        <Box className={styles.help}>
          <Button
            variant="text"
            onClick={() => navigate("/help-with-classification")}
            sx={{
              color: darkMode
                ? theme.palette.secondary.blue300
                : theme.palette.secondary.dark,
              textDecoration: "underline",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            Need help with your classifications?
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        padding: "16px 24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "16px",
        alignSelf: "stretch",
        width: "100%",
      }}
    >
      <SkillsBar userSkills={skills} />
      <Box className={styles.help}>
        <Button
          variant="text"
          onClick={() => navigate("/help-with-classification")}
          sx={{
            color: darkMode
              ? theme.palette.secondary.blue300
              : theme.palette.secondary.dark,
            textDecoration: "underline",
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          Need help with your classifications?
        </Button>
      </Box>
      <DeleteConfirmation
        isOpenDelete={isOpenDelete}
        setIsOpenDelete={setIsOpenDelete}
        deleteSkill={handleDeleteSkillClick}
      />
      <SkillCoverImage />
      <SkillStatus />
      <AboutSkill />
      <Link
        underline="none"
        to={"/editSkill/" + activeSkill.id}
        style={{ width: "100%" }}
      >
        <Button
          variant="contained"
          fullWidth
        >
          Edit
        </Button>
      </Link>
      <Button
        onClick={handleDeleteAcount}
        variant="text"
        sx={{
          p: '0px',
          color: theme.palette.darkgrey.darkgrey400,
          textTransform: "capitalize",
          fontSize: '16px',
          fontWeight: '500',
          width: {
            xs: "100%",
          },
        }}
      >
        Delete
      </Button>
    </Box>
  );
};

export default MySkills;
