import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import LynxLogo from "../../assets/images/Color=Gredient, Size=Large.svg";
import styles from "./creator-registration.module.css";
import HorizontalLinearStepper from "./Stepper/stepper";
import supabase from "../../config/supabaseClient";
import { RegistrationContext } from "../../context/registration-context";
import { UserContext } from "../../context/user-context";
import { LoadingContext } from "../../context/loading-context";
import { useNavigate } from "react-router";
import moment from "moment/moment";
import { calculateUserGeneration } from "../../helpers/calculateUserGeneration";
import axios from "axios";
import Thanks from "./RegisterationThanks/thanks";
import UseLocalStorageState from "../../hooks/userLocalStorage";
import { getAddressInfo } from "../../helpers/getAddressInfo";
import { useTheme } from "../../context/theme-context";
import { addEmailToActiveCampaign } from "../../api/active-campaign";
import TagManager from "react-gtm-module";
function extractUTMParameters(urlString) {
  const params = {};
  const urlParams = new URLSearchParams(urlString);

  // Extract utm_source, utm_campaign, and utm_content parameters
  params.utm_source = urlParams.get("utm_source");
  params.utm_campaign = urlParams.get("utm_campaign");
  params.utm_content = urlParams.get("utm_content");

  return params;
}
const insertUserSkills = async (creatorTypes, userId) => {
  for (let i = 0; i < creatorTypes.length; i++) {
    await supabase
      .from("creator_skills")
      .insert({ user_id: userId, category: creatorTypes[i].category });
  }
};
const fetchUserAddress = async (postalCode, country) => {
  const { data } = await axios.get(
    "https://nominatim.openstreetmap.org/search?format=json&postalcode=" +
      postalCode
  );

  return data.find((item) => item.display_name.includes(country))?.display_name;
};
const CreatorRegistration = () => {
  const { user, setShouldContinueSignUp, setUser } = useContext(UserContext);
  const [isRegisterationFinished, setIsRegisterationFinished] =
    UseLocalStorageState("registeration-finished", false);
  // const [isLoading, setIsLoading] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const {
    periorityCode,
    country,
    pronounce,
    postalCode,
    birthdate,
    collaboratorsTypes,
    creatorTypes,
    completedInfo,
  } = useContext(RegistrationContext);
  const { theme } = useTheme();

  useEffect(() => {
    if (completedInfo && isRegisterationFinished) return;
    if (!completedInfo && isRegisterationFinished)
      setIsRegisterationFinished(false);
  }, []);
  const handleDataComplete = async (e) => {
    if (!user) {
      alert("Can not get your info");
    }
    let shouldInsertStatus = true;
    const { data: statusData } = await supabase
      .from("user_community_status")
      .select()
      .eq("user_id", user.id);
    if (statusData.length > 0) {
      shouldInsertStatus = false;
    }
    setIsLoading(true);
    const addressData = await getAddressInfo(postalCode, country);
    const city = addressData?.city || "";
    const county = addressData?.county || "";
    const state = addressData?.state || "";
    console.log(
      city,
      county,
      state,
      "from city, county , state inside registration"
    );
    const { utm_source, utm_campaign, utm_content } = extractUTMParameters(
      localStorage.getItem("starter-queries")
    );
    const { error, data } = await supabase
      .from("users")
      .update({
        priority_code: periorityCode,
        country,
        generation: calculateUserGeneration(birthdate),
        postal_code: postalCode,
        birth_date: moment(birthdate, "MM-DD-YYYY").format("YYYY-MM-DD"),
        signup_completed: true,
        pronouns: pronounce,
        last_record_change: new Date(),
        city,
        county,
        state,
        utm_info: localStorage.getItem("starter-queries"),
        utm_source,
        utm_campaign,
        utm_content,
      })
      .eq("id", user.id);
    localStorage.removeItem("starter-queries");
    if (!shouldInsertStatus) {
      const { data: updatedUser, error: updatedUserError } = await supabase
        .from("users")
        .select("*")
        .eq("email", user.email);
      await addEmailToActiveCampaign(user.email, user.real_name);

      setUser(updatedUser[0]);
      setShouldContinueSignUp(false);
      navigate("/profile");
      setIsLoading(false);
      return;
    }
    creatorTypes.map(async (item) => {
      const record = {
        category: item.category,
        type: item.creator_type,
        user_id: user.id,
      };
      const { error: creatorsError } = await supabase
        .from("creator_types")
        .insert(record);
    });
    await insertUserSkills(creatorTypes, user.id);
    collaboratorsTypes.map(async (item) => {
      const record = {
        type: item.creator_type,
        category: item.category,
        user_id: user.id,
        display_name: item.collaborator_category,
      };
      const { error: collaboratorsError } = await supabase
        .from("creator_desired_collaborators")
        .insert(record);
    });
    const { error: creatorStatus } = await supabase
      .from("user_community_status")
      .insert({
        user_id: user.id,
        status: "Creator",
        date_acquired: moment().format("YYYY-MM-DD"),
      });
    // const { error: inCompleteStatusError } = await supabase
    //   .from("user_community_status")
    //   .insert({
    //     user_id: user.id,
    //     status: "Incomplete",
    //     date_acquired: moment().format("YYYY-MM-DD"),
    //   });
    // if (error) {
    //   return console.log(error, "from error");
    // }
    const { data: updatedUser, error: updatedUserError } = await supabase
      .from("users")
      .select("*")
      .eq("email", user.email);

    // insert generations
    const result = await ["X", "X+", "Y", "Y+", "Z", "Z+"].map(async (item) => {
      await supabase
        .from("collaborat_age_ranges")
        .insert({ user_id: user.id, age_category: item });
    });
    await addEmailToActiveCampaign(user.email, user.real_name);
    TagManager.dataLayer({
      dataLayer: {
        event: "successful registration",
      },
    });
    console.log("email added");
    setUser(updatedUser[0]);

    setShouldContinueSignUp(false);
    navigate("/profile");
    setIsLoading(false);
  };
  return (
    <React.Fragment>
      {isRegisterationFinished && (
        <Thanks handleDataComplete={handleDataComplete} isLoading={isLoading} />
      )}
      {isRegisterationFinished === false && (
        <Box
          sx={{
            backgroundColor: theme.palette.lightgrey.lightgrey600,
            minHeight: "100vh",
            padding: "1.5rem",
          }}
        >
          <Box
            className={styles.parent}
            sx={{
              backgroundColor: theme.palette.lightgrey.lightgrey00,
            }}
          >
            <Box className={styles.basicLogo}>
              <Container className={styles.content}>
                {/* <img src={LynxLogo} className={styles.logo} alt="Logo" /> */}
                <Box className={styles.text}>
                  <Typography
                    variant="body1"
                    align="center"
                    color={"#FFF"}
                    sx={{
                      fontSize: "36px",
                      fontWeight: "700",
                      lineHeight: "44px",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    Creator Registration
                  </Typography>
                  <Typography variant="body1" align="center" color={"#FFF"}>
                    Apply in less than 60 seconds
                  </Typography>
                </Box>
              </Container>
            </Box>

            <Box className={styles.stepper}>
              <HorizontalLinearStepper
                setIsRegisterationFinished={setIsRegisterationFinished}
              />
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default CreatorRegistration;
