import { Box, Button, Typography } from "@mui/material";
import React from "react";
import logo from "../../../assets/images/Color=Gredient, Size=Large.svg";
import image from "../../../assets/images/award-04.svg";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.css";
import { useTheme } from "../../../context/theme-context";

const DiscoverPlaceholder = () => {
  const navigat = useNavigate();
  const { theme } = useTheme();

  const handleClick = () => {
    navigat("/profile");
  };
  return (
    <Box
      className={styles.discovery}
      sx={{ backgroundColor: theme.palette.lightgrey.lightgrey500 }}
    >
      <Box className={styles.parent}>
        <Box className={styles.logo}>
          <img src={logo} width="234px" alt="LYNX Logo" />
        </Box>
        <Box className={styles.basics}>
          <Box className={styles.iconContainer}>
            <Box
              className={styles.icon}
              sx={{ color: theme.palette.lightgrey.lightgrey00 }}
            >
              <img src={image} alt="image" />
              {/* <TaskAlt style={{ fontSize: "80px" }} /> */}
            </Box>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.text}>
              <Typography
                variant="body1"
                color={theme.palette.darkgrey.darkgrey700}
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "28px",
                  // width: '335px'
                }}
              >
                Now accepting nominations for the Creators' Choice Awards!
              </Typography>
              <Typography
                variant="body1"
                color={theme.palette.darkgrey.darkgrey500}
                sx={{
                  textAlign: "center",
                  // lineHeight: '24px',
                  // width: '335px'
                }}
              >
                To be considered as a top ranked creator, fully complete your
                creator card profile with up to 3 of your top skills. Winners
                will be searchable here in the Discovery section.
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                p: "10px 24px",
                textTransform: "capitalize",
                fontSize: "16px",
                width: "245px",
                width: "264px",
              }}
              onClick={handleClick}
            >
              Complete My Profile
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DiscoverPlaceholder;
