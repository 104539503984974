import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import SkillsBar from "./components/skills-bar";
import SkillCoverImage from "./components/cover-image.js";
import AboutSkill from "./components/about";
import SkillStatus from "./components/status";

import supabase from "../../../../config/supabaseClient.js";
import { useParams } from "react-router-dom";
import LoadingButton from "../../../../components/loading/button";

const MySkills = () => {
  const [skills, setSkills] = useState(null);
  const { id } = useParams();
  const [activeSkill, setActiveSkill] = useState(null);
  const fetchSkills = async (i) => {
    const { data, error } = await supabase
      .from("user_skills")
      .select()
      .eq("user_id", id);
    if (error) {
      if (i === 4) return;
      fetchSkills(i + 1);
    }
    if (!activeSkill) {
      setActiveSkill(data.length > 0 ? data[0] : null);
    }
    setSkills(data);
  };
  useEffect(() => {
    if (id) fetchSkills();
  }, [id]);
  if (!skills) return <div className={styles.loading}><LoadingButton /></div>;

  return (
    <Box className={styles.mySkills}>
      <SkillsBar
        skills={skills}
        activeSkill={activeSkill}
        setActiveSkill={setActiveSkill}
      />
      <SkillCoverImage
        skill={activeSkill}
        fetchSkills={fetchSkills}
        setActiveSkill={setActiveSkill}
      />
      <SkillStatus skill={activeSkill} />
      <AboutSkill skill={activeSkill} />
    </Box>
  );
};

export default MySkills;
